import React, { useState, useEffect } from "react";
import { Filter } from "../filter";
import { Team } from "../../../../../../models/team/team";
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { Loader } from "../../../../../common/loader/Loader.component";
import { useApi } from "../../../../../common/providers/Api.provider";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import "./Teams.component.sass";

export interface TeamsComponentProps {
  filter: Filter;
  setFilter: React.Dispatch<React.SetStateAction<Filter>>;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
}

export function TeamsComponent({
  filter,
  setFilter,
  setHasError,
}: TeamsComponentProps) {
  const { t: teamsTranslations } = useTranslation("data-page", {
    keyPrefix: "steps.teamsSelection",
    useSuspense: false,
  });
  const { t: commonTranslations } = useTranslation("common", {
    useSuspense: false,
  });
  const api = useApi();
  const [teamsState, setTeamsState] = useState<Team[]>([]);
  const [filteredTeamsState, setFilteredTeamsState] = useState<Team[]>([]);
  const [searchTermState, setSearchTermState] = useState<string>("");
  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const getTeams = async () => {
    try {
      setIsLoadingState(true);
      const response = await api.teamsFilter();

      setTeamsStates(response.data);
    } catch (e) {
      console.error(e);
      setHasError(true);
    } finally {
      setIsLoadingState(false);
    }
  };

  const onChangeSearchTerm = (event: { target: { value: string } }) => {
    const searchTerm = event.target.value.toLowerCase();

    setSearchTermState(searchTerm);

    setFilteredTeamsState(
      teamsState.filter(
        (t) =>
          t.id.toString().toLowerCase().includes(searchTerm) ||
          t.name.toLowerCase().includes(searchTerm)
      )
    );
  };

  const setTeamsStates = (teams: Team[]) => {
    setTeamsState(teams);
    setFilteredTeamsState(teams);
  };

  useEffect(() => {
    getTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoadingState) {
    return <Loader loadingText={commonTranslations("loading")} />;
  }

  return (
    <div className="teams-container">
      <form noValidate autoComplete="off">
        <FormControl className="teams-container__search__control">
          <OutlinedInput
            className=""
            type="text"
            placeholder={commonTranslations("search.placeholder")}
            onChange={(event) => onChangeSearchTerm(event)}
            fullWidth={true}
            value={searchTermState}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="search for results" edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </form>
      <DataGrid
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnMenu
        hideFooterPagination
        hideFooterSelectedRowCount
        keepNonExistentRowsSelected={true}
        rows={filteredTeamsState}
        columns={[
          { field: "id", headerName: teamsTranslations("table.id"), width: 70 },
          {
            field: "name",
            headerName: teamsTranslations("table.name"),
            flex: 1,
          },
        ]}
        onRowSelectionModelChange={(newSelection) =>
          setFilter((prevState) => ({
            ...prevState,
            teams: newSelection as Array<number>,
          }))
        }
      />
    </div>
  );
}
